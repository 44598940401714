import firebase from "firebase";

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDFME-BZS8ShCrL6zmS0urNfN2wOqWl-BY",
  authDomain: "vinsen-imagecoach.firebaseapp.com",
  databaseURL: "https://vinsen-imagecoach.firebaseio.com",
  projectId: "vinsen-imagecoach",
  storageBucket: "",
  messagingSenderId: "207386328829",
  appId: "1:207386328829:web:8b4a9fd4e1f48513"
};

firebase.initializeApp(config);
const auth = firebase.auth();
auth.languageCode = 'vi';
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    localStorage._ra___ = user.ra;
  } else {
    // No user is signed in.
  }
});

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
