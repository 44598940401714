import React, { Suspense } from 'react';
import { createBrowserHistory } from "history";
import { Router, Route } from 'react-router-dom';
import './App.css';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

function App() {
  const history = createBrowserHistory();

  const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  return (
    <Suspense fallback={loading()}>
      <Router history = {history}>
        <Route path = "/" component = {App}>
          <DefaultLayout />
        </Route>
    </Router>
   </Suspense>
  );
}

export default App;
