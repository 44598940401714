import {
	ARTICLES_SELECT_ALL,
	ARTICLES_UNSELECT_ALL,
	ARTICLE_SELECTED,
	ARTICLES_LOAD,
	ARTICLES_LOAD_SUCCESS,
	ARTICLES_LOAD_FAILED,
	ARTICLE_LOAD,
	ARTICLE_LOAD_SUCCESS,
	ARTICLE_LOAD_FAILED,
	ARTICLE_ADD,
	ARTICLE_ADD_SUCCESS,
	ARTICLE_ADD_FAILED,
	ARTICLE_UPDATE,
	ARTICLE_UPDATE_SUCCESS,
	ARTICLE_UPDATE_FAILED,
	ARTICLE_DELETE,
	ARTICLE_DELETE_SUCCESS,
	ARTICLE_DELETE_FAILED,
	ARTICLE_DELETE_ALL,
	ARTICLE_DELETE_ALL_SUCCESS,
	ARTICLE_DELETE_ALL_FAILED,
  	ARTICLE_SHOW_LOADING
} from '../constants/ActionTypes';

export const selectAllArticles = () => {
	return {
		type: ARTICLES_SELECT_ALL
	}
}

export const unselectAllArticles = () => {
	return {
		type: ARTICLES_UNSELECT_ALL
	}
}

export const selectArticle = data => {
	return {
		type: ARTICLE_SELECTED,
		payload: data
	}
}

export const loadArticles = (data) => {
	return {
		type: ARTICLES_LOAD,
		payload: data
	};
}

export const loadArticlesSuccess = (data) => {
	return {
		type: ARTICLES_LOAD_SUCCESS,
		payload: data
	};
}

export const loadArticlesFailed = () => {
	return {
		type: ARTICLES_LOAD_FAILED
	};
}

export const loadArticle = () => {
	return {
		type: ARTICLE_LOAD
	};
}

export const loadArticleSuccess = (data) => {
	return {
		type: ARTICLE_LOAD_SUCCESS,
		payload: data
	};
}


export const loadArticleFailed = () => {
	return {
		type: ARTICLE_LOAD_FAILED
	};
}


export const addArticle = (data) => {
	return {
		type: ARTICLE_ADD,
		payload: data
	};
}

export const addArticleSuccess = (filter) => {
	return {
		type: ARTICLE_ADD_SUCCESS,
		message: 'Article added successfully.',
		payload: filter
	};
}

export const addArticleFailed = () => {
	return {
		type: ARTICLE_ADD_FAILED
	};
}

export const updateArticle = (data) => {
	return {
		type: ARTICLE_UPDATE,
		payload: data
	};
}

export const showLoading = (data) => {
  return {
    type: ARTICLE_SHOW_LOADING,
    payload: data
  }
}

export const updateArticleSuccess = (data) => {
	return {
		type: ARTICLE_UPDATE_SUCCESS,
		payload: data
	};
}

export const updateArticleFailed = (data) => {
	return {
		type: ARTICLE_UPDATE_FAILED,
		payload: data
	};
}

export const deleteArticle = (data) => {
	return {
		type: ARTICLE_DELETE,
		payload: data
	};
}

export const deleteArticleSuccess = () => {
	return {
		type: ARTICLE_DELETE_SUCCESS,
		message: 'Article deleted successfully.'
	};
}

export const deleteArticleFailed = () => {
	return {
		type: ARTICLE_DELETE_FAILED
	};
}

export const deleteAllArticles = (data) => {
	return {
		type: ARTICLE_DELETE_ALL,
		payload: data
	};
}

export const deleteAllArticlesSuccess = () => {
	return {
		type: ARTICLE_DELETE_ALL_SUCCESS,
		message: 'Article all deleted successfully.'
	};
}

export const deleteAllArticlesFailed = () => {
	return {
		type: ARTICLE_DELETE_ALL_FAILED
	};
}

