import {
  EVENTS_SELECT_ALL,
  EVENTS_UNSELECT_ALL,
  EVENT_SELECTED,
  EVENTS_LOAD,
  EVENTS_LOAD_SUCCESS,
  EVENTS_LOAD_FAILED,
  EVENT_LOAD,
  EVENT_LOAD_SUCCESS,
  EVENT_LOAD_FAILED,
  EVENT_ADD,
  EVENT_ADD_SUCCESS,
  EVENT_ADD_FAILED,
  EVENT_UPDATE,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAILED,
  EVENT_DELETE,
  EVENT_DELETE_SUCCESS,
  EVENT_DELETE_FAILED,
  EVENT_DELETE_ALL,
  EVENT_DELETE_ALL_SUCCESS,
  EVENT_DELETE_ALL_FAILED,
  EVENT_SHOW_LOADING
} from '../constants/ActionTypes';

const INIT_STATE = {
  companies: {
    records: [],
    offset: 0,
    limit: 1000,
    totalRecords: 0
  },
  selectedEvent: null,
  selectedEventId: null,
  message: null,
  messageType: 'info',
  eventFilter: {
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EVENTS_SELECT_ALL:
      return {
        ...state,
        companies: {
          ...state.companies,
          records: state.companies.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case EVENTS_UNSELECT_ALL:
      return {
        ...state,
        companies: {
          ...state.companies,
          records: state.companies.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case EVENT_SELECTED:
      return {
        ...state,
        selectedEvent: action.payload,
        selectedEventId: action.payload.id
      };
    case EVENTS_LOAD:
      return {
        ...state,
        eventFilter: action.payload ? action.payload : state.EventFilter
      };
    case EVENTS_LOAD_SUCCESS:
      return {
        ...state,
        companies: action.payload
      };
    case EVENTS_LOAD_FAILED:
      return {
        ...state
      };
    case EVENT_LOAD:
      return {
        ...state
      };
    case EVENT_LOAD_SUCCESS:
      return {
        ...state
      };
    case EVENT_LOAD_FAILED:
      return {
        ...state
      };
    case EVENT_ADD:
      return {
        ...state
      };
    case EVENT_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case EVENT_ADD_FAILED:
      return {
        ...state
      };
    case EVENT_UPDATE:
      return {
        ...state
      };
    case EVENT_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case EVENT_UPDATE_FAILED:
      return {
        ...state
      };
    case EVENT_DELETE:
      return {
        ...state
      };
    case EVENT_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case EVENT_DELETE_FAILED:
      return {
        ...state
      };
    case EVENT_DELETE_ALL:
      return {
        ...state
      };
    case EVENT_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case EVENT_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case EVENT_SHOW_LOADING:
      return {
        ...state,
        hideLoading: false
      }
    default:
      return state;
  }
}
