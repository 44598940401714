import {
  STUDENTS_SELECT_ALL,
  STUDENTS_UNSELECT_ALL,
  STUDENT_SELECTED,
  STUDENTS_LOAD,
  STUDENTS_LOAD_SUCCESS,
  STUDENTS_LOAD_FAILED,
  STUDENT_LOAD,
  STUDENT_LOAD_SUCCESS,
  STUDENT_LOAD_FAILED,
  STUDENT_ADD,
  STUDENT_ADD_SUCCESS,
  STUDENT_ADD_FAILED,
  STUDENT_UPDATE,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAILED,
  STUDENT_DELETE,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_FAILED,
  STUDENT_DELETE_ALL,
  STUDENT_DELETE_ALL_SUCCESS,
  STUDENT_DELETE_ALL_FAILED,
  STUDENT_SHOW_LOADING
} from '../constants/ActionTypes';

const INIT_STATE = {
  students: {
    records: [],
    offset: 0,
    limit: 1000,
    totalRecords: 0
  },
  selectedStudent: null,
  selectedStudentId: null,
  message: null,
  messageType: 'info',
  studentFilter: {
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STUDENTS_SELECT_ALL:
      return {
        ...state,
        students: {
          ...state.students,
          records: state.students.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case STUDENTS_UNSELECT_ALL:
      return {
        ...state,
        students: {
          ...state.students,
          records: state.students.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case STUDENT_SELECTED:
      return {
        ...state,
        selectedStudent: action.payload,
        selectedStudentId: action.payload.id
      };
    case STUDENTS_LOAD:
      return {
        ...state,
        studentFilter: action.payload ? action.payload : state.StudentFilter
      };
    case STUDENTS_LOAD_SUCCESS:
      return {
        ...state,
        students: action.payload
      };
    case STUDENTS_LOAD_FAILED:
      return {
        ...state
      };
    case STUDENT_LOAD:
      return {
        ...state
      };
    case STUDENT_LOAD_SUCCESS:
      return {
        ...state
      };
    case STUDENT_LOAD_FAILED:
      return {
        ...state
      };
    case STUDENT_ADD:
      return {
        ...state
      };
    case STUDENT_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_ADD_FAILED:
      return {
        ...state
      };
    case STUDENT_UPDATE:
      return {
        ...state
      };
    case STUDENT_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_UPDATE_FAILED:
      return {
        ...state
      };
    case STUDENT_DELETE:
      return {
        ...state
      };
    case STUDENT_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case STUDENT_DELETE_FAILED:
      return {
        ...state
      };
    case STUDENT_DELETE_ALL:
      return {
        ...state
      };
    case STUDENT_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case STUDENT_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case STUDENT_SHOW_LOADING:
      return {
        ...state,
        hideLoading: false
      }
    default:
      return state;
  }
}
