import {all} from "redux-saga/effects";
import articleSagas from "./Article";
import courseSagas from "./Course";
import eventSagas from "./Event";
import settingSagas from "./Setting";
import studentSagas from "./Student";
import teacherSagas from './Teacher';
import authSaga from './Auth';
import userSagas from './User';

export default function* rootSaga(getState) {
  yield all([
    articleSagas(),
    courseSagas(),
    eventSagas(),
    settingSagas(),
    studentSagas(),
    teacherSagas(),
    authSaga(),
    userSagas()
  ]);
}
