import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadSettings as loadSettingsAction,
    loadSettingSuccess,
    loadSettingsSuccess,
    addSettingSuccess,
    updateSettingSuccess,
    deleteSettingSuccess,
    deleteAllSettingsSuccess
} from '../actions/Setting';

import {
    loadSettingsFailed,
    loadSettingFailed,
    addSettingFailed,
    updateSettingFailed,
    deleteSettingFailed
} from '../actions/Setting';

import {
    SETTINGS_LOAD,
    SETTING_LOAD,
    SETTING_ADD,
    SETTING_UPDATE,
    SETTING_DELETE,
    SETTING_DELETE_ALL,
    SETTING_DELETE_ALL_SUCCESS,
    SETTING_DELETE_ALL_FAILED,
    SETTING_ADD_SUCCESS,
    SETTING_UPDATE_SUCCESS,
} from '../constants/ActionTypes';

const loadSettingsRequest = async (params) => {
    if (params.filterGroups && params.filterGroups.length) {
        return await Api().post(`setting/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`setting`, { params })
        .then(data => data)
        .catch(error => error);
}

const loadSettingRequest = async (id) =>
    await Api().get(`setting/${id}`)
        .then(data => data)
        .catch(error => error);

const addSettingRequest = async (payload) =>
    await Api().post(`setting`, payload)
        .then(data => data)
        .catch(error => error);

const updateSettingRequest = async (payload) =>
    await Api().patch(`setting/${payload.id}`, patchData(payload))
        .then(data => data)
        .catch(error => error);

const deleteSettingRequest = async (id) =>
    await Api().delete(`setting/${id}`)
        .then(data => data)
        .catch(error => error);

function* loadSettings({ payload }) {
    try {
        const data = yield call(loadSettingsRequest, payload);
        yield put(loadSettingsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshSettings({ payload }) {
    yield loadSettings(payload);
}

function* processLoadSettings() {
    yield takeEvery(SETTINGS_LOAD, loadSettings);
}

function* loadSetting({ selectedSettingId }) {
    try {
        const data = yield call(loadSettingsRequest, selectedSettingId);
        yield put(loadSettingsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadSetting() {
    yield takeEvery(SETTINGS_LOAD, loadSetting);
}


function* addSetting({ payload }) {
    try {
        yield call(addSettingRequest, payload.model);
        yield put(loadSettingsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddSetting() {
    yield takeEvery(SETTING_ADD, addSetting);
}


function* updateSetting({ payload }) {
    try {
        yield call(updateSettingRequest, payload.model);
        yield put(loadSettingsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateSetting() {
    yield takeEvery(SETTING_UPDATE, updateSetting);
}

function* deleteSetting({ payload }) {
    try {
        yield call(deleteSettingRequest, payload.model.id);
        yield put(loadSettingsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllSettings({ payload }) {
    try {
        for (let i = 0; i < payload.models.length; ++i) {
            yield call(deleteSettingRequest, payload.models[i].id);
        }

        yield put(loadSettingsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteSetting() {
    yield takeEvery(SETTING_DELETE, deleteSetting);
}

function* processDeleteAllSettings() {
    yield takeEvery(SETTING_DELETE_ALL, deleteAllSettings);
}

export default function* SettingSagas() {
    yield all([fork(processLoadSettings),
    fork(processLoadSetting),
    fork(processAddSetting),
    fork(processUpdateSetting),
    fork(processDeleteAllSettings),
    fork(processDeleteSetting)]);
}
