import {
	USER_SEARCH,
	USER_SEARCH_SUCCESS,
	USER_ASSIGN_ROLES,
	USER_ASSIGN_ROLES_SUCCESS,
	USER_LOCK,
	USER_LOCK_SUCCESS
} from '../constants/ActionTypes';

export const searchUsers = (data) => {
	return {
		type: USER_SEARCH,
		payload: data
	};
}


export const searchUsersSuccess = (data) => {
	return {
		type: USER_SEARCH_SUCCESS,
		payload: data
	};
}

export const assignUserToRoles = (data) => {
	return {
		type: USER_ASSIGN_ROLES,
		payload: data
	};
}

export const assignUserToRolesSuccess = () => {
	return {
		type: USER_ASSIGN_ROLES_SUCCESS
	};
}

export const lockUser = (data) => {
	return {
		type: USER_LOCK,
		payload: data
	};
}

export const lockUserSuccess = () => {
	return {
		type: USER_LOCK_SUCCESS
	};
}