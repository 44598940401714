import {
  TEACHERS_SELECT_ALL,
  TEACHERS_UNSELECT_ALL,
  TEACHER_SELECTED,
  TEACHERS_LOAD,
  TEACHERS_LOAD_SUCCESS,
  TEACHERS_LOAD_FAILED,
  TEACHER_LOAD,
  TEACHER_LOAD_SUCCESS,
  TEACHER_LOAD_FAILED,
  TEACHER_ADD,
  TEACHER_ADD_SUCCESS,
  TEACHER_ADD_FAILED,
  TEACHER_UPDATE,
  TEACHER_UPDATE_SUCCESS,
  TEACHER_UPDATE_FAILED,
  TEACHER_DELETE,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_FAILED,
  TEACHER_DELETE_ALL,
  TEACHER_DELETE_ALL_SUCCESS,
  TEACHER_DELETE_ALL_FAILED,
  TEACHER_SHOW_LOADING
} from '../constants/ActionTypes';

const INIT_STATE = {
  teachers: {
    records: [],
    offset: 0,
    limit: 1000,
    totalRecords: 0
  },
  selectedTeacher: null,
  selectedTeacherId: null,
  message: null,
  messageType: 'info',
  teacherFilter: {
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEACHERS_SELECT_ALL:
      return {
        ...state,
        teachers: {
          ...state.teachers,
          records: state.teachers.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case TEACHERS_UNSELECT_ALL:
      return {
        ...state,
        teachers: {
          ...state.teachers,
          records: state.teachers.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case TEACHER_SELECTED:
      return {
        ...state,
        selectedTeacher: action.payload,
        selectedTeacherId: action.payload.id
      };
    case TEACHERS_LOAD:
      return {
        ...state,
        teacherFilter: action.payload ? action.payload : state.TeacherFilter
      };
    case TEACHERS_LOAD_SUCCESS:
      return {
        ...state,
        teachers: action.payload
      };
    case TEACHERS_LOAD_FAILED:
      return {
        ...state
      };
    case TEACHER_LOAD:
      return {
        ...state
      };
    case TEACHER_LOAD_SUCCESS:
      return {
        ...state
      };
    case TEACHER_LOAD_FAILED:
      return {
        ...state
      };
    case TEACHER_ADD:
      return {
        ...state
      };
    case TEACHER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case TEACHER_ADD_FAILED:
      return {
        ...state
      };
    case TEACHER_UPDATE:
      return {
        ...state
      };
    case TEACHER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case TEACHER_UPDATE_FAILED:
      return {
        ...state
      };
    case TEACHER_DELETE:
      return {
        ...state
      };
    case TEACHER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case TEACHER_DELETE_FAILED:
      return {
        ...state
      };
    case TEACHER_DELETE_ALL:
      return {
        ...state
      };
    case TEACHER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case TEACHER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case TEACHER_SHOW_LOADING:
      return {
        ...state,
        hideLoading: false
      }
    default:
      return state;
  }
}
