import {
	USER_SEARCH,
	USER_ASSIGN_ROLES,
    USER_LOCK,
    USER_SEARCH_SUCCESS,
    USER_ASSIGN_ROLES_SUCCESS,
    USER_LOCK_SUCCESS
} from '../constants/ActionTypes';
  
const INIT_STATE = {
    paging: {
        records: [],
        offset: 0,
		limit: 50,
		toalPages: 1,
		totalRecords: 0
    },
    selectedUser: null,
	filter: {
		offset: 0,
		limit: 50,
		orderBy: 'userName',
		filterGroups: [{
            filters:[{
                property: 'userName',
                operator: 'contains',
                value: ''
            }]
        }]
	  }
};
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case USER_SEARCH: {
        return {
          ...state,
          loader: true,
          filter: action.payload
        }
      }
      case USER_SEARCH_SUCCESS: {
        return {
          ...state,
          loader: false,
          paging: action.payload
        }
      }
      case USER_ASSIGN_ROLES: {
        return {
          ...state,
          loader: true
        }
      }
      case USER_ASSIGN_ROLES_SUCCESS: {
        return {
          ...state,
          loader: false
        }
      }
  
      case USER_LOCK: {
        return {
          ...state,
          loader: true
        }
      }
      case USER_LOCK_SUCCESS: {
        return {
          ...state,
          loader: false
        }
      }  
      default:
        return state;
    }
  }
  