import {
	TEACHERS_SELECT_ALL,
	TEACHERS_UNSELECT_ALL,
	TEACHER_SELECTED,
	TEACHERS_LOAD,
	TEACHERS_LOAD_SUCCESS,
	TEACHERS_LOAD_FAILED,
	TEACHER_LOAD,
	TEACHER_LOAD_SUCCESS,
	TEACHER_LOAD_FAILED,
	TEACHER_ADD,
	TEACHER_ADD_SUCCESS,
	TEACHER_ADD_FAILED,
	TEACHER_UPDATE,
	TEACHER_UPDATE_SUCCESS,
	TEACHER_UPDATE_FAILED,
	TEACHER_DELETE,
	TEACHER_DELETE_SUCCESS,
	TEACHER_DELETE_FAILED,
	TEACHER_DELETE_ALL,
	TEACHER_DELETE_ALL_SUCCESS,
	TEACHER_DELETE_ALL_FAILED,
  	TEACHER_SHOW_LOADING
} from '../constants/ActionTypes';

export const selectAllTeachers = () => {
	return {
		type: TEACHERS_SELECT_ALL
	}
}

export const unselectAllTeachers = () => {
	return {
		type: TEACHERS_UNSELECT_ALL
	}
}

export const selectTeacher = data => {
	return {
		type: TEACHER_SELECTED,
		payload: data
	}
}

export const loadTeachers = (data) => {
	return {
		type: TEACHERS_LOAD,
		payload: data
	};
}

export const loadTeachersSuccess = (data) => {
	return {
		type: TEACHERS_LOAD_SUCCESS,
		payload: data
	};
}

export const loadTeachersFailed = () => {
	return {
		type: TEACHERS_LOAD_FAILED
	};
}

export const loadTeacher = () => {
	return {
		type: TEACHER_LOAD
	};
}

export const loadTeacherSuccess = (data) => {
	return {
		type: TEACHER_LOAD_SUCCESS,
		payload: data
	};
}


export const loadTeacherFailed = () => {
	return {
		type: TEACHER_LOAD_FAILED
	};
}


export const addTeacher = (data) => {
	return {
		type: TEACHER_ADD,
		payload: data
	};
}


export const addTeacherSuccess = (filter) => {
	return {
		type: TEACHER_ADD_SUCCESS,
		message: 'Teacher added successfully.',
		payload: filter
	};
}

export const addTeacherFailed = () => {
	return {
		type: TEACHER_ADD_FAILED
	};
}

export const updateTeacher = (data) => {
	return {
		type: TEACHER_UPDATE,
		payload: data
	};
}

export const showLoading = (data) => {
  return {
    type: TEACHER_SHOW_LOADING,
    payload: data
  }
}

export const updateTeacherSuccess = (data) => {
	return {
		type: TEACHER_UPDATE_SUCCESS,
		payload: data
	};
}

export const updateTeacherFailed = (data) => {
	return {
		type: TEACHER_UPDATE_FAILED,
		payload: data
	};
}

export const deleteTeacher = (data) => {
	return {
		type: TEACHER_DELETE,
		payload: data
	};
}

export const deleteTeacherSuccess = () => {
	return {
		type: TEACHER_DELETE_SUCCESS,
		message: 'Teacher deleted successfully.'
	};
}

export const deleteTeacherFailed = () => {
	return {
		type: TEACHER_DELETE_FAILED
	};
}

export const deleteAllTeachers = (data) => {
	return {
		type: TEACHER_DELETE_ALL,
		payload: data
	};
}

export const deleteAllTeachersSuccess = () => {
	return {
		type: TEACHER_DELETE_ALL_SUCCESS,
		message: 'Teacher all deleted successfully.'
	};
}

export const deleteAllTeachersFailed = () => {
	return {
		type: TEACHER_DELETE_ALL_FAILED
	};
}

