// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

// Article const
export const ARTICLES_SELECT_ALL = 'ARTICLES_SELECT_ALL';
export const ARTICLES_UNSELECT_ALL = 'ARTICLES_UNSELECT_ALL';
export const ARTICLE_SELECTED = 'ARTICLE_SELECTED';
export const ARTICLE_LOAD = 'ARTICLE_LOAD';
export const ARTICLE_LOAD_SUCCESS = 'ARTICLE_LOAD_SUCCESS';
export const ARTICLE_LOAD_FAILED = 'ARTICLE_LOAD_FAILED';
export const ARTICLES_LOAD = 'ARTICLES_LOAD';
export const ARTICLES_LOAD_SUCCESS = 'ARTICLES_LOAD_SUCCESS';
export const ARTICLES_LOAD_FAILED = 'ARTICLES_LOAD_FAILED';
export const ARTICLE_ADD = 'ARTICLE_ADD';
export const ARTICLE_ADD_SUCCESS = 'ARTICLE_ADD_SUCCESS';
export const ARTICLE_ADD_FAILED = 'ARTICLE_ADD_FAILED';
export const ARTICLE_UPDATE = 'ARTICLE_UPDATE';
export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS';
export const ARTICLE_UPDATE_FAILED = 'ARTICLE_UPDATE_FAILED';
export const ARTICLE_DELETE = 'ARTICLE_DELETE';
export const ARTICLE_DELETE_SUCCESS = 'ARTICLE_DELETE_SUCCESS';
export const ARTICLE_DELETE_FAILED = 'ARTICLE_DELETE_FAILED';
export const ARTICLE_DELETE_ALL = 'ARTICLE_DELETE_ALL';
export const ARTICLE_DELETE_ALL_SUCCESS = 'ARTICLE_DELETE_ALL_SUCCESS';
export const ARTICLE_DELETE_ALL_FAILED = 'ARTICLE_DELETE_ALL_FAILED';
export const ARTICLE_SHOW_LOADING = 'ARTICLE_SHOW_LOADING';

// Course const
export const COURSES_SELECT_ALL = 'COURSES_SELECT_ALL';
export const COURSES_UNSELECT_ALL = 'COURSES_UNSELECT_ALL';
export const COURSE_SELECTED = 'COURSE_SELECTED';
export const COURSE_LOAD = 'COURSE_LOAD';
export const COURSE_LOAD_SUCCESS = 'COURSE_LOAD_SUCCESS';
export const COURSE_LOAD_FAILED = 'COURSE_LOAD_FAILED';
export const COURSES_LOAD = 'COURSES_LOAD';
export const COURSES_LOAD_SUCCESS = 'COURSES_LOAD_SUCCESS';
export const COURSES_LOAD_FAILED = 'COURSES_LOAD_FAILED';
export const COURSE_ADD = 'COURSE_ADD';
export const COURSE_ADD_SUCCESS = 'COURSE_ADD_SUCCESS';
export const COURSE_ADD_FAILED = 'COURSE_ADD_FAILED';
export const COURSE_UPDATE = 'COURSE_UPDATE';
export const COURSE_UPDATE_SUCCESS = 'COURSE_UPDATE_SUCCESS';
export const COURSE_UPDATE_FAILED = 'COURSE_UPDATE_FAILED';
export const COURSE_DELETE = 'COURSE_DELETE';
export const COURSE_DELETE_SUCCESS = 'COURSE_DELETE_SUCCESS';
export const COURSE_DELETE_FAILED = 'COURSE_DELETE_FAILED';
export const COURSE_DELETE_ALL = 'COURSE_DELETE_ALL';
export const COURSE_DELETE_ALL_SUCCESS = 'COURSE_DELETE_ALL_SUCCESS';
export const COURSE_DELETE_ALL_FAILED = 'COURSE_DELETE_ALL_FAILED';
export const COURSE_SHOW_LOADING = 'COURSE_SHOW_LOADING';

// Teacher const
export const TEACHERS_SELECT_ALL = 'TEACHERS_SELECT_ALL';
export const TEACHERS_UNSELECT_ALL = 'TEACHERS_UNSELECT_ALL';
export const TEACHER_SELECTED = 'TEACHER_SELECTED';
export const TEACHER_LOAD = 'TEACHER_LOAD';
export const TEACHER_LOAD_SUCCESS = 'TEACHER_LOAD_SUCCESS';
export const TEACHER_LOAD_FAILED = 'TEACHER_LOAD_FAILED';
export const TEACHERS_LOAD = 'TEACHERS_LOAD';
export const TEACHERS_LOAD_SUCCESS = 'TEACHERS_LOAD_SUCCESS';
export const TEACHERS_LOAD_FAILED = 'TEACHERS_LOAD_FAILED';
export const TEACHER_ADD = 'TEACHER_ADD';
export const TEACHER_ADD_SUCCESS = 'TEACHER_ADD_SUCCESS';
export const TEACHER_ADD_FAILED = 'TEACHER_ADD_FAILED';
export const TEACHER_UPDATE = 'TEACHER_UPDATE';
export const TEACHER_UPDATE_SUCCESS = 'TEACHER_UPDATE_SUCCESS';
export const TEACHER_UPDATE_FAILED = 'TEACHER_UPDATE_FAILED';
export const TEACHER_DELETE = 'TEACHER_DELETE';
export const TEACHER_DELETE_SUCCESS = 'TEACHER_DELETE_SUCCESS';
export const TEACHER_DELETE_FAILED = 'TEACHER_DELETE_FAILED';
export const TEACHER_DELETE_ALL = 'TEACHER_DELETE_ALL';
export const TEACHER_DELETE_ALL_SUCCESS = 'TEACHER_DELETE_ALL_SUCCESS';
export const TEACHER_DELETE_ALL_FAILED = 'TEACHER_DELETE_ALL_FAILED';
export const TEACHER_SHOW_LOADING = 'TEACHER_SHOW_LOADING';

// Student const
export const STUDENTS_SELECT_ALL = 'STUDENTS_SELECT_ALL';
export const STUDENTS_UNSELECT_ALL = 'STUDENTS_UNSELECT_ALL';
export const STUDENT_SELECTED = 'STUDENT_SELECTED';
export const STUDENT_LOAD = 'STUDENT_LOAD';
export const STUDENT_LOAD_SUCCESS = 'STUDENT_LOAD_SUCCESS';
export const STUDENT_LOAD_FAILED = 'STUDENT_LOAD_FAILED';
export const STUDENTS_LOAD = 'STUDENTS_LOAD';
export const STUDENTS_LOAD_SUCCESS = 'STUDENTS_LOAD_SUCCESS';
export const STUDENTS_LOAD_FAILED = 'STUDENTS_LOAD_FAILED';
export const STUDENT_ADD = 'STUDENT_ADD';
export const STUDENT_ADD_SUCCESS = 'STUDENT_ADD_SUCCESS';
export const STUDENT_ADD_FAILED = 'STUDENT_ADD_FAILED';
export const STUDENT_UPDATE = 'STUDENT_UPDATE';
export const STUDENT_UPDATE_SUCCESS = 'STUDENT_UPDATE_SUCCESS';
export const STUDENT_UPDATE_FAILED = 'STUDENT_UPDATE_FAILED';
export const STUDENT_DELETE = 'STUDENT_DELETE';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';
export const STUDENT_DELETE_FAILED = 'STUDENT_DELETE_FAILED';
export const STUDENT_DELETE_ALL = 'STUDENT_DELETE_ALL';
export const STUDENT_DELETE_ALL_SUCCESS = 'STUDENT_DELETE_ALL_SUCCESS';
export const STUDENT_DELETE_ALL_FAILED = 'STUDENT_DELETE_ALL_FAILED';
export const STUDENT_SHOW_LOADING = 'STUDENT_SHOW_LOADING';

// Event const
export const EVENTS_SELECT_ALL = 'EVENTS_SELECT_ALL';
export const EVENTS_UNSELECT_ALL = 'EVENTS_UNSELECT_ALL';
export const EVENT_SELECTED = 'EVENT_SELECTED';
export const EVENT_LOAD = 'EVENT_LOAD';
export const EVENT_LOAD_SUCCESS = 'EVENT_LOAD_SUCCESS';
export const EVENT_LOAD_FAILED = 'EVENT_LOAD_FAILED';
export const EVENTS_LOAD = 'EVENTS_LOAD';
export const EVENTS_LOAD_SUCCESS = 'EVENTS_LOAD_SUCCESS';
export const EVENTS_LOAD_FAILED = 'EVENTS_LOAD_FAILED';
export const EVENT_ADD = 'EVENT_ADD';
export const EVENT_ADD_SUCCESS = 'EVENT_ADD_SUCCESS';
export const EVENT_ADD_FAILED = 'EVENT_ADD_FAILED';
export const EVENT_UPDATE = 'EVENT_UPDATE';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_FAILED = 'EVENT_UPDATE_FAILED';
export const EVENT_DELETE = 'EVENT_DELETE';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_FAILED = 'EVENT_DELETE_FAILED';
export const EVENT_DELETE_ALL = 'EVENT_DELETE_ALL';
export const EVENT_DELETE_ALL_SUCCESS = 'EVENT_DELETE_ALL_SUCCESS';
export const EVENT_DELETE_ALL_FAILED = 'EVENT_DELETE_ALL_FAILED';
export const EVENT_SHOW_LOADING = 'EVENT_SHOW_LOADING';

// Setting const
export const SETTINGS_SELECT_ALL = 'SETTINGS_SELECT_ALL';
export const SETTINGS_UNSELECT_ALL = 'SETTINGS_UNSELECT_ALL';
export const SETTING_SELECTED = 'SETTING_SELECTED';
export const SETTING_LOAD = 'SETTING_LOAD';
export const SETTING_LOAD_SUCCESS = 'SETTING_LOAD_SUCCESS';
export const SETTING_LOAD_FAILED = 'SETTING_LOAD_FAILED';
export const SETTINGS_LOAD = 'SETTINGS_LOAD';
export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const SETTINGS_LOAD_FAILED = 'SETTINGS_LOAD_FAILED';
export const SETTING_ADD = 'SETTING_ADD';
export const SETTING_ADD_SUCCESS = 'SETTING_ADD_SUCCESS';
export const SETTING_ADD_FAILED = 'SETTING_ADD_FAILED';
export const SETTING_UPDATE = 'SETTING_UPDATE';
export const SETTING_UPDATE_SUCCESS = 'SETTING_UPDATE_SUCCESS';
export const SETTING_UPDATE_FAILED = 'SETTING_UPDATE_FAILED';
export const SETTING_DELETE = 'SETTING_DELETE';
export const SETTING_DELETE_SUCCESS = 'SETTING_DELETE_SUCCESS';
export const SETTING_DELETE_FAILED = 'SETTING_DELETE_FAILED';
export const SETTING_DELETE_ALL = 'SETTING_DELETE_ALL';
export const SETTING_DELETE_ALL_SUCCESS = 'SETTING_DELETE_ALL_SUCCESS';
export const SETTING_DELETE_ALL_FAILED = 'SETTING_DELETE_ALL_FAILED';
export const SETTING_SHOW_LOADING = 'SETTING_SHOW_LOADING';

// User const
export const USER_SEARCH = 'USER_SEARCH';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_ASSIGN_ROLES = 'USER_ASSIGN_ROLES';
export const USER_ASSIGN_ROLES_SUCCESS = 'USER_ASSIGN_ROLES_SUCCESS';
export const USER_LOCK = 'USER_LOCK';
export const USER_LOCK_SUCCESS = 'USER_LOCK_SUCCESS';

// Web info const
export const WEB_SETTINGS = 'WEB_SETTINGS';