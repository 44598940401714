import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadArticles as loadArticlesAction,
    loadArticlesSuccess,
    addArticleSuccess,
    updateArticleSuccess,
    deleteArticleSuccess,
    deleteAllArticlesSuccess
} from '../actions/Article';

import {
    ARTICLES_LOAD,
    ARTICLE_ADD,
    ARTICLE_UPDATE,
    ARTICLE_DELETE,
    ARTICLE_DELETE_ALL,
} from '../constants/ActionTypes';

import { userSignOut } from '../actions/Auth';

const loadArticlesRequest = async (params) => {
    if (params.filterGroups && params.filterGroups.length) {
        return await Api().post(`article/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`article`, { params })
        .then(data => data)
        .catch(error => error);
}


const addArticleRequest = async (payload) =>
    await Api().post(`article`, payload)
        .then(data => data)
        .catch(error => error);

const updateArticleRequest = async (payload) =>
    await Api().patch(`article/${payload.id}`, patchData(payload))
        .then(data => data)
        .catch(error => error);

const deleteArticleRequest = async (id) =>
    await Api().delete(`article/${id}`)
        .then(data => data)
        .catch(error => error);

function* loadArticles({ payload }) {
    try {
        const data = yield call(loadArticlesRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadArticlesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}


function* processLoadArticles() {
    yield takeEvery(ARTICLES_LOAD, loadArticles);
}

function* loadArticle({ selectedArticleId }) {
    try {
        const data = yield call(loadArticlesRequest, selectedArticleId);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(loadArticlesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadArticle() {
    yield takeEvery(ARTICLES_LOAD, loadArticle);
}

function* addArticle({ payload }) {
    try {
        const data = yield call(addArticleRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(addArticleSuccess());
        yield put(loadArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddArticle() {
    yield takeEvery(ARTICLE_ADD, addArticle);
}


function* updateArticle({ payload }) {
    try {
        const data = yield call(updateArticleRequest, payload.model);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(updateArticleSuccess());
        yield put(loadArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateArticle() {
    yield takeEvery(ARTICLE_UPDATE, updateArticle);
}

function* deleteArticle({ payload }) {
    try {
        const data = yield call(deleteArticleRequest, payload.model.id);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(deleteArticleSuccess());
        yield put(loadArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllArticles({ payload }) {
    try {
        for (let i = 0; i < payload.models.length; ++i) {
            const data = yield call(deleteArticleRequest, payload.models[i].id);
            if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
        }

        yield put(deleteAllArticlesSuccess());
        yield put(loadArticlesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteArticle() {
    yield takeEvery(ARTICLE_DELETE, deleteArticle);
}

function* processDeleteAllArticles() {
    yield takeEvery(ARTICLE_DELETE_ALL, deleteAllArticles);
}

export default function* articleSagas() {
    yield all([fork(processLoadArticles),
    fork(processLoadArticle),
    fork(processAddArticle),
    fork(processUpdateArticle),
    fork(processDeleteAllArticles),
    fork(processDeleteArticle)]);
}
