import {
  ARTICLES_SELECT_ALL,
  ARTICLES_UNSELECT_ALL,
  ARTICLE_SELECTED,
  ARTICLES_LOAD,
  ARTICLES_LOAD_SUCCESS,
  ARTICLES_LOAD_FAILED,
  ARTICLE_LOAD,
  ARTICLE_LOAD_SUCCESS,
  ARTICLE_LOAD_FAILED,
  ARTICLE_ADD,
  ARTICLE_ADD_SUCCESS,
  ARTICLE_ADD_FAILED,
  ARTICLE_UPDATE,
  ARTICLE_UPDATE_SUCCESS,
  ARTICLE_UPDATE_FAILED,
  ARTICLE_DELETE,
  ARTICLE_DELETE_SUCCESS,
  ARTICLE_DELETE_FAILED,
  ARTICLE_DELETE_ALL,
  ARTICLE_DELETE_ALL_SUCCESS,
  ARTICLE_DELETE_ALL_FAILED,
  ARTICLE_SHOW_LOADING
} from '../constants/ActionTypes';

const INIT_STATE = {
  articles: {
    records: [],
    offset: 0,
    limit: 1000,
    totalRecords: 0
  },
  selectedArticle: null,
  selectedArticleId: null,
  message: null,
  messageType: 'info',
  articleFilter: {
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ARTICLES_SELECT_ALL:
      return {
        ...state,
        articles: {
          ...state.articles,
          records: state.articles.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case ARTICLES_UNSELECT_ALL:
      return {
        ...state,
        articles: {
          ...state.articles,
          records: state.articles.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case ARTICLE_SELECTED:
      return {
        ...state,
        selectedArticle: action.payload,
        selectedArticleId: action.payload.id
      };
    case ARTICLES_LOAD:
      return {
        ...state,
        articleFilter: action.payload ? action.payload : state.ArticleFilter
      };
    case ARTICLES_LOAD_SUCCESS:
      return {
        ...state,
        articles: action.payload
      };
    case ARTICLES_LOAD_FAILED:
      return {
        ...state
      };
    case ARTICLE_LOAD:
      return {
        ...state
      };
    case ARTICLE_LOAD_SUCCESS:
      return {
        ...state
      };
    case ARTICLE_LOAD_FAILED:
      return {
        ...state
      };
    case ARTICLE_ADD:
      return {
        ...state
      };
    case ARTICLE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ARTICLE_ADD_FAILED:
      return {
        ...state
      };
    case ARTICLE_UPDATE:
      return {
        ...state
      };
    case ARTICLE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ARTICLE_UPDATE_FAILED:
      return {
        ...state
      };
    case ARTICLE_DELETE:
      return {
        ...state
      };
    case ARTICLE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ARTICLE_DELETE_FAILED:
      return {
        ...state
      };
    case ARTICLE_DELETE_ALL:
      return {
        ...state
      };
    case ARTICLE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ARTICLE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case ARTICLE_SHOW_LOADING:
      return {
        ...state,
        hideLoading: false
      }
    default:
      return state;
  }
}
