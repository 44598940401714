import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadStudents as loadStudentsAction,
    loadStudentSuccess,
    loadStudentsSuccess,
    addStudentSuccess,
    updateStudentSuccess,
    deleteStudentSuccess,
    deleteAllStudentsSuccess
} from '../actions/Student';

import {
    loadStudentsFailed,
    loadStudentFailed,
    addStudentFailed,
    updateStudentFailed,
    deleteStudentFailed
} from '../actions/Student';

import {
    STUDENTS_LOAD,
    STUDENT_LOAD,
    STUDENT_ADD,
    STUDENT_UPDATE,
    STUDENT_DELETE,
    STUDENT_DELETE_ALL,
    STUDENT_DELETE_ALL_SUCCESS,
    STUDENT_DELETE_ALL_FAILED,
    STUDENT_ADD_SUCCESS,
    STUDENT_UPDATE_SUCCESS,
} from '../constants/ActionTypes';

const loadStudentsRequest = async (params) => {
    if (params.filterGroups && params.filterGroups.length) {
        return await Api().post(`student/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`student`, { params })
        .then(data => data)
        .catch(error => error);
}

const loadStudentRequest = async (id) =>
    await Api().get(`student/${id}`)
        .then(data => data)
        .catch(error => error);

const addStudentRequest = async (payload) =>
    await Api().post(`student`, payload)
        .then(data => data)
        .catch(error => error);

const updateStudentRequest = async (payload) =>
    await Api().patch(`student/${payload.id}`, patchData(payload))
        .then(data => data)
        .catch(error => error);

const deleteStudentRequest = async (id) =>
    await Api().delete(`student/${id}`)
        .then(data => data)
        .catch(error => error);

function* loadStudents({ payload }) {
    try {
        const data = yield call(loadStudentsRequest, payload);
        yield put(loadStudentsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshStudents({ payload }) {
    yield loadStudents(payload);
}

function* processLoadStudents() {
    yield takeEvery(STUDENTS_LOAD, loadStudents);
}

function* loadStudent({ selectedStudentId }) {
    try {
        const data = yield call(loadStudentsRequest, selectedStudentId);
        yield put(loadStudentsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadStudent() {
    yield takeEvery(STUDENTS_LOAD, loadStudent);
}

function* addStudent({ payload }) {
    try {
        yield call(addStudentRequest, payload.model);
        yield put(addStudentSuccess());
        yield put(loadStudentsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddStudent() {
    yield takeEvery(STUDENT_ADD, addStudent);
}


function* updateStudent({ payload }) {
    try {
        yield call(updateStudentRequest, payload.model);
        yield put(updateStudentSuccess());
        yield put(loadStudentsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateStudent() {
    yield takeEvery(STUDENT_UPDATE, updateStudent);
}

function* deleteStudent({ payload }) {
    try {
        yield call(deleteStudentRequest, payload.model.id);
        yield put(loadStudentsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllStudents({ payload }) {
    try {
        for (let i = 0; i < payload.models.length; ++i) {
            yield call(deleteStudentRequest, payload.models[i].id);
        }

        yield put(loadStudentsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteStudent() {
    yield takeEvery(STUDENT_DELETE, deleteStudent);
}

function* processDeleteAllStudents() {
    yield takeEvery(STUDENT_DELETE_ALL, deleteAllStudents);
}

export default function* studentSagas() {
    yield all([fork(processLoadStudents),
    fork(processLoadStudent),
    fork(processAddStudent),
    fork(processUpdateStudent),
    fork(processDeleteAllStudents),
    fork(processDeleteStudent)]);
}
