import {
	STUDENTS_SELECT_ALL,
	STUDENTS_UNSELECT_ALL,
	STUDENT_SELECTED,
	STUDENTS_LOAD,
	STUDENTS_LOAD_SUCCESS,
	STUDENTS_LOAD_FAILED,
	STUDENT_LOAD,
	STUDENT_LOAD_SUCCESS,
	STUDENT_LOAD_FAILED,
	STUDENT_ADD,
	STUDENT_ADD_SUCCESS,
	STUDENT_ADD_FAILED,
	STUDENT_UPDATE,
	STUDENT_UPDATE_SUCCESS,
	STUDENT_UPDATE_FAILED,
	STUDENT_DELETE,
	STUDENT_DELETE_SUCCESS,
	STUDENT_DELETE_FAILED,
	STUDENT_DELETE_ALL,
	STUDENT_DELETE_ALL_SUCCESS,
	STUDENT_DELETE_ALL_FAILED,
  	STUDENT_SHOW_LOADING
} from '../constants/ActionTypes';

export const selectAllStudents = () => {
	return {
		type: STUDENTS_SELECT_ALL
	}
}

export const unselectAllStudents = () => {
	return {
		type: STUDENTS_UNSELECT_ALL
	}
}

export const selectStudent = data => {
	return {
		type: STUDENT_SELECTED,
		payload: data
	}
}

export const loadStudents = (data) => {
	return {
		type: STUDENTS_LOAD,
		payload: data
	};
}

export const loadStudentsSuccess = (data) => {
	return {
		type: STUDENTS_LOAD_SUCCESS,
		payload: data
	};
}

export const loadStudentsFailed = () => {
	return {
		type: STUDENTS_LOAD_FAILED
	};
}

export const loadStudent = () => {
	return {
		type: STUDENT_LOAD
	};
}

export const loadStudentSuccess = (data) => {
	return {
		type: STUDENT_LOAD_SUCCESS,
		payload: data
	};
}


export const loadStudentFailed = () => {
	return {
		type: STUDENT_LOAD_FAILED
	};
}


export const addStudent = (data) => {
	return {
		type: STUDENT_ADD,
		payload: data
	};
}


export const addStudentSuccess = (filter) => {
	return {
		type: STUDENT_ADD_SUCCESS,
		message: 'Student added successfully.',
		payload: filter
	};
}

export const addStudentFailed = () => {
	return {
		type: STUDENT_ADD_FAILED
	};
}

export const updateStudent = (data) => {
	return {
		type: STUDENT_UPDATE,
		payload: data
	};
}

export const showLoading = (data) => {
  return {
    type: STUDENT_SHOW_LOADING,
    payload: data
  }
}

export const updateStudentSuccess = (data) => {
	return {
		type: STUDENT_UPDATE_SUCCESS,
		payload: data
	};
}

export const updateStudentFailed = (data) => {
	return {
		type: STUDENT_UPDATE_FAILED,
		payload: data
	};
}

export const deleteStudent = (data) => {
	return {
		type: STUDENT_DELETE,
		payload: data
	};
}

export const deleteStudentSuccess = () => {
	return {
		type: STUDENT_DELETE_SUCCESS,
		message: 'Student deleted successfully.'
	};
}

export const deleteStudentFailed = () => {
	return {
		type: STUDENT_DELETE_FAILED
	};
}

export const deleteAllStudents = (data) => {
	return {
		type: STUDENT_DELETE_ALL,
		payload: data
	};
}

export const deleteAllStudentsSuccess = () => {
	return {
		type: STUDENT_DELETE_ALL_SUCCESS,
		message: 'Student all deleted successfully.'
	};
}

export const deleteAllStudentsFailed = () => {
	return {
		type: STUDENT_DELETE_ALL_FAILED
	};
}

