import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadTeachers as loadTeachersAction,
    loadTeacherSuccess,
    loadTeachersSuccess,
    addTeacherSuccess,
    updateTeacherSuccess,
    deleteTeacherSuccess,
    deleteAllTeachersSuccess
} from '../actions/Teacher';

import {
    loadTeachersFailed,
    loadTeacherFailed,
    addTeacherFailed,
    updateTeacherFailed,
    deleteTeacherFailed
} from '../actions/Teacher';

import {
    TEACHERS_LOAD,
    TEACHER_LOAD,
    TEACHER_ADD,
    TEACHER_UPDATE,
    TEACHER_DELETE,
    TEACHER_DELETE_ALL,
    TEACHER_DELETE_ALL_SUCCESS,
    TEACHER_DELETE_ALL_FAILED,
    TEACHER_ADD_SUCCESS,
    TEACHER_UPDATE_SUCCESS,
} from '../constants/ActionTypes';

const loadTeachersRequest = async (params) => {
    if (params.filterGroups && params.filterGroups.length) {
        return await Api().post(`teacher/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`teacher`, { params })
        .then(data => data)
        .catch(error => error);
}

const loadTeacherRequest = async (id) =>
    await Api().get(`teacher/${id}`)
        .then(data => data)
        .catch(error => error);

const addTeacherRequest = async (payload) =>
    await Api().post(`teacher`, payload)
        .then(data => data)
        .catch(error => error);

const updateTeacherRequest = async (payload) =>
    await Api().patch(`teacher/${payload.id}`, patchData(payload))
        .then(data => data)
        .catch(error => error);

const deleteTeacherRequest = async (id) =>
    await Api().delete(`teacher/${id}`)
        .then(data => data)
        .catch(error => error);

function* loadTeachers({ payload }) {
    try {
        const data = yield call(loadTeachersRequest, payload);
        yield put(loadTeachersSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshTeachers({ payload }) {
    yield loadTeachers(payload);
}

function* processLoadTeachers() {
    yield takeEvery(TEACHERS_LOAD, loadTeachers);
}

function* loadTeacher({ selectedTeacherId }) {
    try {
        const data = yield call(loadTeachersRequest, selectedTeacherId);
        yield put(loadTeachersSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadTeacher() {
    yield takeEvery(TEACHERS_LOAD, loadTeacher);
}

function* addTeacher({ payload }) {
    try {
        yield call(addTeacherRequest, payload.model);
        yield put(addTeacherSuccess());
        yield put(loadTeachersAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddTeacher() {
    yield takeEvery(TEACHER_ADD, addTeacher);
}


function* updateTeacher({ payload }) {
    try {
        yield call(updateTeacherRequest, payload.model);
        yield put(updateTeacherSuccess());
        yield put(loadTeachersAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateTeacher() {
    yield takeEvery(TEACHER_UPDATE, updateTeacher);
}

function* deleteTeacher({ payload }) {
    try {
        yield call(deleteTeacherRequest, payload.model.id);
        yield put(loadTeachersAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllTeachers({ payload }) {
    try {
        for (let i = 0; i < payload.models.length; ++i) {
            yield call(deleteTeacherRequest, payload.models[i].id);
        }

        yield put(loadTeachersAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteTeacher() {
    yield takeEvery(TEACHER_DELETE, deleteTeacher);
}

function* processDeleteAllTeachers() {
    yield takeEvery(TEACHER_DELETE_ALL, deleteAllTeachers);
}

export default function* teacherSagas() {
    yield all([fork(processLoadTeachers),
    fork(processLoadTeacher),
    fork(processAddTeacher),
    fork(processUpdateTeacher),
    fork(processDeleteAllTeachers),
    fork(processDeleteTeacher)]);
}
