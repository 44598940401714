import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadCourses as loadCoursesAction,
    loadCourseSuccess,
    loadCoursesSuccess,
    addCourseSuccess,
    updateCourseSuccess,
    deleteCourseSuccess,
    deleteAllCoursesSuccess
} from '../actions/Course';

import {
    loadCoursesFailed,
    loadCourseFailed,
    addCourseFailed,
    updateCourseFailed,
    deleteCourseFailed
} from '../actions/Course';

import {
    COURSES_LOAD,
    COURSE_LOAD,
    COURSE_ADD,
    COURSE_UPDATE,
    COURSE_DELETE,
    COURSE_DELETE_ALL,
    COURSE_DELETE_ALL_SUCCESS,
    COURSE_DELETE_ALL_FAILED,
    COURSE_ADD_SUCCESS,
    COURSE_UPDATE_SUCCESS,
} from '../constants/ActionTypes';

const loadCoursesRequest = async (params) => {
    if (params.filterGroups && params.filterGroups.length) {
        return await Api().post(`course/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`course`, { params })
        .then(data => data)
        .catch(error => error);
}

const loadCourseRequest = async (id) =>
    await Api().get(`course/${id}`)
        .then(data => data)
        .catch(error => error);

const addCourseRequest = async (payload) =>
    await Api().post(`course`, payload)
        .then(data => data)
        .catch(error => error);

const updateCourseRequest = async (payload) =>
    await Api().patch(`course/${payload.id}`, patchData(payload))
        .then(data => data)
        .catch(error => error);

const deleteCourseRequest = async (id) =>
    await Api().delete(`course/${id}`)
        .then(data => data)
        .catch(error => error);

function* loadCourses({ payload }) {
    try {
        const data = yield call(loadCoursesRequest, payload);
        yield put(loadCoursesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshCourses({ payload }) {
    yield loadCourses(payload);
}

function* processLoadCourses() {
    yield takeEvery(COURSES_LOAD, loadCourses);
}

function* loadCourse({ selectedCourseId }) {
    try {
        const data = yield call(loadCoursesRequest, selectedCourseId);
        yield put(loadCoursesSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadCourse() {
    yield takeEvery(COURSES_LOAD, loadCourse);
}

function* addCourse({ payload }) {
    try {
        yield call(addCourseRequest, payload.model);
        yield put(addCourseSuccess());
        yield put(loadCoursesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddCourse() {
    yield takeEvery(COURSE_ADD, addCourse);
}


function* updateCourse({ payload }) {
    try {
        yield call(updateCourseRequest, payload.model);
        yield put(updateCourseSuccess());
        yield put(loadCoursesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateCourse() {
    yield takeEvery(COURSE_UPDATE, updateCourse);
}

function* deleteCourse({ payload }) {
    try {
        yield call(deleteCourseRequest, payload.model.id);
        yield put(loadCoursesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllCourses({ payload }) {
    try {
        for (let i = 0; i < payload.models.length; ++i) {
            yield call(deleteCourseRequest, payload.models[i].id);
        }

        yield put(loadCoursesAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteCourse() {
    yield takeEvery(COURSE_DELETE, deleteCourse);
}

function* processDeleteAllCourses() {
    yield takeEvery(COURSE_DELETE_ALL, deleteAllCourses);
}

export default function* courseSagas() {
    yield all([fork(processLoadCourses),
    fork(processLoadCourse),
    fork(processAddCourse),
    fork(processUpdateCourse),
    fork(processDeleteAllCourses),
    fork(processDeleteCourse)]);
}
