import {
  COURSES_SELECT_ALL,
  COURSES_UNSELECT_ALL,
  COURSE_SELECTED,
  COURSES_LOAD,
  COURSES_LOAD_SUCCESS,
  COURSES_LOAD_FAILED,
  COURSE_LOAD,
  COURSE_LOAD_SUCCESS,
  COURSE_LOAD_FAILED,
  COURSE_ADD,
  COURSE_ADD_SUCCESS,
  COURSE_ADD_FAILED,
  COURSE_UPDATE,
  COURSE_UPDATE_SUCCESS,
  COURSE_UPDATE_FAILED,
  COURSE_DELETE,
  COURSE_DELETE_SUCCESS,
  COURSE_DELETE_FAILED,
  COURSE_DELETE_ALL,
  COURSE_DELETE_ALL_SUCCESS,
  COURSE_DELETE_ALL_FAILED,
  COURSE_SHOW_LOADING
} from '../constants/ActionTypes';

const INIT_STATE = {
  courses: {
    records: [],
    offset: 0,
    limit: 1000,
    totalRecords: 0
  },
  selectedCourse: null,
  selectedCourseId: null,
  message: null,
  messageType: 'info',
  courseFilter: {
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case COURSES_SELECT_ALL:
      return {
        ...state,
        courses: {
          ...state.courses,
          records: state.courses.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case COURSES_UNSELECT_ALL:
      return {
        ...state,
        courses: {
          ...state.courses,
          records: state.courses.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case COURSE_SELECTED:
      return {
        ...state,
        selectedCourse: action.payload,
        selectedCourseId: action.payload.id
      };
    case COURSES_LOAD:
      return {
        ...state,
        courseFilter: action.payload ? action.payload : state.CourseFilter
      };
    case COURSES_LOAD_SUCCESS:
      return {
        ...state,
        courses: action.payload
      };
    case COURSES_LOAD_FAILED:
      return {
        ...state
      };
    case COURSE_LOAD:
      return {
        ...state
      };
    case COURSE_LOAD_SUCCESS:
      return {
        ...state
      };
    case COURSE_LOAD_FAILED:
      return {
        ...state
      };
    case COURSE_ADD:
      return {
        ...state
      };
    case COURSE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case COURSE_ADD_FAILED:
      return {
        ...state
      };
    case COURSE_UPDATE:
      return {
        ...state
      };
    case COURSE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case COURSE_UPDATE_FAILED:
      return {
        ...state
      };
    case COURSE_DELETE:
      return {
        ...state
      };
    case COURSE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case COURSE_DELETE_FAILED:
      return {
        ...state
      };
    case COURSE_DELETE_ALL:
      return {
        ...state
      };
    case COURSE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case COURSE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case COURSE_SHOW_LOADING:
      return {
        ...state,
        hideLoading: false
      }
    default:
      return state;
  }
}
