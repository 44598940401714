import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api from './Api';


import {
    searchUsersSuccess,
    assignUserToRolesSuccess,
    lockUserSuccess
} from '../actions/User';

import {
	USER_SEARCH,
	USER_ASSIGN_ROLES,
	USER_LOCK,
} from '../constants/ActionTypes';

const searchUsersRequest = async (params) => {
    return await Api().post(`users/search`, params)
            .then(data => data)
            .catch(error => error);
}

const assignUserToRolesRequest = async (params) => {
    return await Api().post(`users/assign-roles`, params)
            .then(data => data)
            .catch(error => error);
}

const lockUserRequest = async (params) => {
    return await Api().post(`users/lock`, params)
            .then(data => data)
            .catch(error => error);
}

function* searchUsers({ payload }) {
    try {
        const data = yield call(searchUsersRequest, payload);
        yield put(searchUsersSuccess(data.data));
    } catch (error) {
    }
}

function* assignUserToRoles({ payload }) {
    try {
        yield call(assignUserToRolesRequest, payload.info);
        yield put(searchUsers({ payload: payload.filter }));
    } catch (error) {
    }
}

function* lockUser({ payload }) {
    try {
        yield call(lockUserRequest, payload.info);
        yield put(searchUsers({ payload: payload.filter }));
    } catch (error) {
    }
}

function* processSearchUsers() {
    yield takeEvery(USER_SEARCH, searchUsers);
}

function* processAssignUserToRoles() {
    yield takeEvery(USER_ASSIGN_ROLES, assignUserToRoles);
}

function* processLockUser() {
    yield takeEvery(USER_LOCK, lockUser);
}

export default function* userSagas() {
    yield all([fork(processSearchUsers),
    fork(processAssignUserToRoles),
    fork(processLockUser)]);
}