import {
	COURSES_SELECT_ALL,
	COURSES_UNSELECT_ALL,
	COURSE_SELECTED,
	COURSES_LOAD,
	COURSES_LOAD_SUCCESS,
	COURSES_LOAD_FAILED,
	COURSE_LOAD,
	COURSE_LOAD_SUCCESS,
	COURSE_LOAD_FAILED,
	COURSE_ADD,
	COURSE_ADD_SUCCESS,
	COURSE_ADD_FAILED,
	COURSE_UPDATE,
	COURSE_UPDATE_SUCCESS,
	COURSE_UPDATE_FAILED,
	COURSE_DELETE,
	COURSE_DELETE_SUCCESS,
	COURSE_DELETE_FAILED,
	COURSE_DELETE_ALL,
	COURSE_DELETE_ALL_SUCCESS,
	COURSE_DELETE_ALL_FAILED,
  	COURSE_SHOW_LOADING
} from '../constants/ActionTypes';

export const selectAllCourses = () => {
	return {
		type: COURSES_SELECT_ALL
	}
}

export const unselectAllCourses = () => {
	return {
		type: COURSES_UNSELECT_ALL
	}
}

export const selectCourse = data => {
	return {
		type: COURSE_SELECTED,
		payload: data
	}
}

export const loadCourses = (data) => {
	return {
		type: COURSES_LOAD,
		payload: data
	};
}

export const loadCoursesSuccess = (data) => {
	return {
		type: COURSES_LOAD_SUCCESS,
		payload: data
	};
}

export const loadCoursesFailed = () => {
	return {
		type: COURSES_LOAD_FAILED
	};
}

export const loadCourse = () => {
	return {
		type: COURSE_LOAD
	};
}

export const loadCourseSuccess = (data) => {
	return {
		type: COURSE_LOAD_SUCCESS,
		payload: data
	};
}


export const loadCourseFailed = () => {
	return {
		type: COURSE_LOAD_FAILED
	};
}


export const addCourse = (data) => {
	return {
		type: COURSE_ADD,
		payload: data
	};
}


export const addCourseSuccess = (filter) => {
	return {
		type: COURSE_ADD_SUCCESS,
		message: 'Course added successfully.',
		payload: filter
	};
}

export const addCourseFailed = () => {
	return {
		type: COURSE_ADD_FAILED
	};
}

export const updateCourse = (data) => {
	return {
		type: COURSE_UPDATE,
		payload: data
	};
}

export const showLoading = (data) => {
  return {
    type: COURSE_SHOW_LOADING,
    payload: data
  }
}

export const updateCourseSuccess = (data) => {
	return {
		type: COURSE_UPDATE_SUCCESS,
		payload: data
	};
}

export const updateCourseFailed = (data) => {
	return {
		type: COURSE_UPDATE_FAILED,
		payload: data
	};
}

export const deleteCourse = (data) => {
	return {
		type: COURSE_DELETE,
		payload: data
	};
}

export const deleteCourseSuccess = () => {
	return {
		type: COURSE_DELETE_SUCCESS,
		message: 'Course deleted successfully.'
	};
}

export const deleteCourseFailed = () => {
	return {
		type: COURSE_DELETE_FAILED
	};
}

export const deleteAllCourses = (data) => {
	return {
		type: COURSE_DELETE_ALL,
		payload: data
	};
}

export const deleteAllCoursesSuccess = () => {
	return {
		type: COURSE_DELETE_ALL_SUCCESS,
		message: 'Course all deleted successfully.'
	};
}

export const deleteAllCoursesFailed = () => {
	return {
		type: COURSE_DELETE_ALL_FAILED
	};
}

