import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
    loadEvents as loadEventsAction,
    loadEventSuccess,
    loadEventsSuccess,
    addEventSuccess,
    updateEventSuccess,
    deleteEventSuccess,
    deleteAllEventsSuccess
} from '../actions/Event';

import {
    loadEventsFailed,
    loadEventFailed,
    addEventFailed,
    updateEventFailed,
    deleteEventFailed
} from '../actions/Event';

import {
    EVENTS_LOAD,
    EVENT_LOAD,
    EVENT_ADD,
    EVENT_UPDATE,
    EVENT_DELETE,
    EVENT_DELETE_ALL,
    EVENT_DELETE_ALL_SUCCESS,
    EVENT_DELETE_ALL_FAILED,
    EVENT_ADD_SUCCESS,
    EVENT_UPDATE_SUCCESS,
} from '../constants/ActionTypes';

const loadEventsRequest = async (params) => {
    if (params.filterGroups && params.filterGroups.length) {
        return await Api().post(`event/search`, params)
            .then(data => data)
            .catch(error => error);
    }

    return await Api().get(`event`, { params })
        .then(data => data)
        .catch(error => error);
}

const loadEventRequest = async (id) =>
    await Api().get(`event/${id}`)
        .then(data => data)
        .catch(error => error);

const addEventRequest = async (payload) =>
    await Api().post(`event`, payload)
        .then(data => data)
        .catch(error => error);

const updateEventRequest = async (payload) =>
    await Api().patch(`event/${payload.id}`, patchData(payload))
        .then(data => data)
        .catch(error => error);

const deleteEventRequest = async (id) =>
    await Api().delete(`event/${id}`)
        .then(data => data)
        .catch(error => error);

function* loadEvents({ payload }) {
    try {
        const data = yield call(loadEventsRequest, payload);
        yield put(loadEventsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* refreshEvents({ payload }) {
    yield loadEvents(payload);
}

function* processLoadEvents() {
    yield takeEvery(EVENTS_LOAD, loadEvents);
}

function* loadEvent({ selectedEventId }) {
    try {
        const data = yield call(loadEventsRequest, selectedEventId);
        yield put(loadEventsSuccess(data.data));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processLoadEvent() {
    yield takeEvery(EVENTS_LOAD, loadEvent);
}

function* addEvent({ payload }) {
    try {
        yield call(addEventRequest, payload.model);
        yield put(addEventSuccess());
        yield put(loadEventsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processAddEvent() {
    yield takeEvery(EVENT_ADD, addEvent);
}


function* updateEvent({ payload }) {
    try {
        yield call(updateEventRequest, payload.model);
        yield put(updateEventSuccess());
        yield put(loadEventsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processUpdateEvent() {
    yield takeEvery(EVENT_UPDATE, updateEvent);
}

function* deleteEvent({ payload }) {
    try {
        yield call(deleteEventRequest, payload.model.id);
        yield put(loadEventsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* deleteAllEvents({ payload }) {
    try {
        for (let i = 0; i < payload.models.length; ++i) {
            yield call(deleteEventRequest, payload.models[i].id);
        }

        yield put(loadEventsAction(payload.filter));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* processDeleteEvent() {
    yield takeEvery(EVENT_DELETE, deleteEvent);
}

function* processDeleteAllEvents() {
    yield takeEvery(EVENT_DELETE_ALL, deleteAllEvents);
}

export default function* eventSagas() {
    yield all([fork(processLoadEvents),
    fork(processLoadEvent),
    fork(processAddEvent),
    fork(processUpdateEvent),
    fork(processDeleteAllEvents),
    fork(processDeleteEvent)]);
}
