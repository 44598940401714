import {
	EVENTS_SELECT_ALL,
	EVENTS_UNSELECT_ALL,
	EVENT_SELECTED,
	EVENTS_LOAD,
	EVENTS_LOAD_SUCCESS,
	EVENTS_LOAD_FAILED,
	EVENT_LOAD,
	EVENT_LOAD_SUCCESS,
	EVENT_LOAD_FAILED,
	EVENT_ADD,
	EVENT_ADD_SUCCESS,
	EVENT_ADD_FAILED,
	EVENT_UPDATE,
	EVENT_UPDATE_SUCCESS,
	EVENT_UPDATE_FAILED,
	EVENT_DELETE,
	EVENT_DELETE_SUCCESS,
	EVENT_DELETE_FAILED,
	EVENT_DELETE_ALL,
	EVENT_DELETE_ALL_SUCCESS,
	EVENT_DELETE_ALL_FAILED,
  	EVENT_SHOW_LOADING
} from '../constants/ActionTypes';

export const selectAllEvents = () => {
	return {
		type: EVENTS_SELECT_ALL
	}
}

export const unselectAllEvents = () => {
	return {
		type: EVENTS_UNSELECT_ALL
	}
}

export const selectEvent = data => {
	return {
		type: EVENT_SELECTED,
		payload: data
	}
}

export const loadEvents = (data) => {
	return {
		type: EVENTS_LOAD,
		payload: data
	};
}

export const loadEventsSuccess = (data) => {
	return {
		type: EVENTS_LOAD_SUCCESS,
		payload: data
	};
}

export const loadEventsFailed = () => {
	return {
		type: EVENTS_LOAD_FAILED
	};
}

export const loadEvent = () => {
	return {
		type: EVENT_LOAD
	};
}

export const loadEventSuccess = (data) => {
	return {
		type: EVENT_LOAD_SUCCESS,
		payload: data
	};
}


export const loadEventFailed = () => {
	return {
		type: EVENT_LOAD_FAILED
	};
}


export const addEvent = (data) => {
	return {
		type: EVENT_ADD,
		payload: data
	};
}


export const addEventSuccess = (filter) => {
	return {
		type: EVENT_ADD_SUCCESS,
		message: 'Event added successfully.',
		payload: filter
	};
}

export const addEventFailed = () => {
	return {
		type: EVENT_ADD_FAILED
	};
}

export const updateEvent = (data) => {
	return {
		type: EVENT_UPDATE,
		payload: data
	};
}

export const showLoading = (data) => {
  return {
    type: EVENT_SHOW_LOADING,
    payload: data
  }
}

export const updateEventSuccess = (data) => {
	return {
		type: EVENT_UPDATE_SUCCESS,
		payload: data
	};
}

export const updateEventFailed = (data) => {
	return {
		type: EVENT_UPDATE_FAILED,
		payload: data
	};
}

export const deleteEvent = (data) => {
	return {
		type: EVENT_DELETE,
		payload: data
	};
}

export const deleteEventSuccess = () => {
	return {
		type: EVENT_DELETE_SUCCESS,
		message: 'Event deleted successfully.'
	};
}

export const deleteEventFailed = () => {
	return {
		type: EVENT_DELETE_FAILED
	};
}

export const deleteAllEvents = (data) => {
	return {
		type: EVENT_DELETE_ALL,
		payload: data
	};
}

export const deleteAllEventsSuccess = () => {
	return {
		type: EVENT_DELETE_ALL_SUCCESS,
		message: 'Event all deleted successfully.'
	};
}

export const deleteAllEventsFailed = () => {
	return {
		type: EVENT_DELETE_ALL_FAILED
	};
}

