import {    
    WEB_SETTINGS
} from '../constants/ActionTypes';
  
  const INIT_STATE = {
    websettings: {
      pageInfo: {}
    }
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case WEB_SETTINGS:
        return {
          ...state,
          websettings: {
            ...state,
            pageInfo: action.payload
          }
        }
      default:
        return state;
    }
  }
  