import {combineReducers} from "redux";
import Article from "./Article";
import Course from './Course';
import Event from './Event';
import Student from './Student';
import Teacher from './Teacher';
import Setting from './Setting';
import Common from './Common';
import Auth from "./Auth";
import User from "./User";
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
  router: connectRouter(history),
  article: Article,  
  course: Course,
  event: Event,
  student: Student,
  teacher: Teacher,
  setting: Setting,  
  common: Common,
  auth: Auth,
  user: User  
});